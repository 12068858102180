import React from 'react';
import headerImg2 from '../assets/headerImg2.jpg';
//import headerImg from '../assets/headerImg.jpg';

const Header = () => {
  return (
	<header id="snacking-grill" className="bg-slate-200 h-[200px] md:h-[400px] lg:h-[600px] select-none">
		<img src={headerImg2} alt="Plateau de burgers" className=" object-cover h-[200px] md:h-[400px] lg:h-[600px] w-full" />
	</header>
  )
}

export default Header;