import React from 'react';
import Card from '../components/Card';
import CardSauce from '../components/CardSauce';
import CardDrink from '../components/CardDrink';
import items from '../data/items';
import {FaPhoneAlt, FaSearchLocation} from 'react-icons/fa';

const Home = () => {

  const CheckAssiettes = items.some(item => {
    if (item.category === "Assiettes") {
      return true;
    }
    return false;
  });

  const CheckBurgers = items.some(item => {
    if (item.category === "Burgers") {
      return true;
    }
    return false;
  });

  const CheckTacos = items.some(item => {
    if (item.category === "Tacos") {
      return true;
    }
    return false;
  });

  const CheckPaninis = items.some(item => {
    if (item.category === "Paninis") {
      return true;
    }
    return false;
  });

  const CheckKebabMaison= items.some(item => {
    if (item.category === "Kebab pain maison") {
      return true;
    }
    return false;
  });

  const CheckAmericains = items.some(item => {
    if (item.category === "Américains") {
      return true;
    }
    return false;
  });

  const CheckNuggets = items.some(item => {
    if (item.category === "Nuggets") {
      return true;
    }
    return false;
  });

  const CheckFrites = items.some(item => {
    if (item.category === "Frites") {
      return true;
    }
    return false;
  });

  const CheckMenus = items.some(item => {
    if (item.category === "Menus") {
      return true;
    }
    return false;
  });

  const CheckSauces = items.some(item => {
    if (item.category === "Sauces") {
      return true;
    }
    return false;
  });

  const CheckDrinks = items.some(item => {
    if (item.category === "Boissons") {
      return true;
    }
    return false;
  });

  return (
  <div className="container mx-auto">

    <div className="flex justify-center select-none">
      <h1 className="font-bold text-xl border-t-2 border-gray-200">&emsp; Menu &emsp;</h1>
    </div>

    {
      CheckAssiettes &&
      <>
        <div id="assiettes" className="flex justify-center mt-10 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">A</span>SSIETTES</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Assiettes").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckBurgers &&
      <>
        <div id="burgers" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">B</span>URGERS</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Burgers").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckTacos &&
      <>
        <div id="tacos" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">T</span>ACOS</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Tacos").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckKebabMaison &&
      <>
        <div id="kebabmaison" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">K</span>EBABS <span className="text-amber-500">M</span>AISON</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Kebab pain maison").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckPaninis &&
      <>
        <div id="paninis" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">P</span>ANINIS</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Paninis").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckAmericains &&
      <>
        <div id="americains" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">A</span>MÉRICAINS</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Américains").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckMenus &&
      <>
        <div id="menus" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">M</span>ENUS</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Menus").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckNuggets &&
      <>
        <div id="nuggets" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl"><span className="text-amber-500">N</span>OS <span className="text-amber-500">N</span>UGGETS</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid  grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Nuggets").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }
      
    {  
      CheckFrites &&
      <>
        <div id="barquettes" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl "><span className="text-amber-500">N</span>OS <span className="text-amber-500">B</span>ARQUETTES</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Frites").map(itemFiltered => {
                  return (
                    <Card title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckSauces &&
      <>
        <div id="sauces" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl "><span className="text-amber-500">N</span>OS <span className="text-amber-500">S</span>AUCES</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Sauces").map(itemFiltered => {
                  return (
                    <CardSauce title={itemFiltered.name}/>
                  )
                })
              }
          </div>
        </div>
      </>
    }

    {  
      CheckDrinks &&
      <>
        <div id="boissons" className="flex justify-center mt-10 md:mt-20 lg:mt-25 select-none">
          <h1 className="font-bold text-4xl xs:text-3xl "><span className="text-amber-500">N</span>OS <span className="text-amber-500">B</span>OISSONS</h1>
        </div>

        <div className="flex md:justify-center mt-10 px-5 md:mt-20 lg:mt-25">
          <div className="grid grid-flow-row-dense xs:grid-cols-custom-xs sm:grid-cols-custom-sm md:grid-cols-custom-md lg:grid-cols-custom-lg xl:grid-cols-custom-xl gap-5 gap-x-10">
              {
                items.filter(item => item.category === "Boissons").map(itemFiltered => {
                  return (
                    <CardDrink title={itemFiltered.name} desc={itemFiltered.desc} cat={itemFiltered.category} img={itemFiltered.url} price={itemFiltered.price} />
                  )
                })
              }
          </div>
        </div>
      </>
    }

    <div className="flex justify-center mt-14">
      <hr className="border-t-1 border-amber-300 w-1/3"/>
    </div>

    <div id="a-propos" className="bg-gray-100 mt-10 shadow-lg px-4 m-2">
      <div className="flex flex-col justify-center px-6 py-4">
          <h1 className="mt-4 font-semibold text-center">1 AVENUE DU COUSERANS 31260 MANE</h1>
          <br />
          <p className="text-justify">
          L’équipe du  <span className="font-semibold italic">Snacking Grill</span>  vous remercie pour votre visite et vous présente son établissement. 
          <br />
          L’accueil et le service sont assurés par <span className="font-semibold italic">Laurent</span> tandis que <span className="font-semibold italic">Nadia</span> s’occupe de vous cuisiner vos savoureux repas. 
          </p>
          <br />
          <p className="text-justify">Afin de conserver toutes les saveurs et vous proposer le meilleur, <span className="font-semibold italic">Nadia</span> cuisine tous les jours avec des légumes frais (salades, tomates, oignons… etc).
             Tout comme nous sélectionnons nos viandes (steak de bœuf, filet de poulet) avec grand soin chez notre boucher. </p>
          <br />
          <p className="text-justify">
          Nos nuggets et nos tenders sont faits maison à partir de filets de poulet; 
          tous nos pains sont faits sur place et nous vous proposons de déguster nos fameux desserts maison. 
          </p>
          <br />
          <p className="text-justify">Nous aurons le plaisir de vous accueillir et vous servir dans notre établissement sur place ou à emporter ! </p>
          <br />
          <p className="text-justify">
          Des places de parking <span className="font-semibold italic">gratuites</span>, attenantes au snack, sont mises à votre disposition. 
          Notre restaurant est accessible pour les personnes à mobilité réduite.
          </p>
      </div>
    </div>

    <div className="flex justify-center mt-14">
      <hr className="border-t-1 border-amber-300 w-1/3" />
    </div>

    <div id="horraires" className="bg-gray-100 mt-10 shadow-lg m-2">
      <div className="flex xs:flex-col sm:flex-col md:flex-row justify-center px-6 py-4">
        <div className="basis-1/3 px-4">
          <h1 className="text-center uppercase font-semibold">Coordonnées</h1>
          <div className="flex flex-col justify-center text-center mt-5">
            <a className="bg-amber-400 hover:bg-amber-300 h-[50px] flex items-center justify-center rounded-lg" href="tel:09 54 91 98 63" target="_blank" rel="noreferrer"><FaPhoneAlt /><span className="ml-1 font-semibold">Appeler</span></a>
            <span className="mt-2">09 54 91 98 63</span>
          </div>
        </div>
        <div className="basis-1/3 px-4 xs:mt-10 sm:mt-10 md:mt-0">
          <h1 className="text-center uppercase font-semibold">Adresse</h1>
          <div className="flex flex-col text-center mt-5">
            <a className="bg-amber-400 hover:bg-amber-300 h-[50px] flex items-center justify-center rounded-lg" href="https://www.google.com/maps/dir//Snacking+Grill-burger-restauration+rapide+a+Mane+3/@43.0829603,0.8812547,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12a8ddb2c9d66bb1:0xa9892f2ba4942a3d!2m2!1d0.9512945!2d43.0829817" target="_blank" rel="noreferrer"><FaSearchLocation /><span className="ml-1 font-semibold">Itinéraire</span></a>
          </div>
        </div>
        <div className="basis-1/3 px-4 xs:mt-10 sm:mt-10 md:mt-0">
          <h1 className="text-center uppercase font-semibold">Horaires d'ouverture</h1>
          <div className="flex flex-row justify-center mt-5">
            <div className="text-right">
              <ul>
                <li>Lun:</li>
                <li>Mar:</li>
                <li>Mer:</li>
                <li>Jeu:</li>
                <li>Ven:</li>
                <li>Sam: </li>
                <li>Dim:</li>
              </ul>
            </div>
            <div className="ml-2">
              <ul>
                <li>Fermé</li>
                <li>Fermé</li>
                <li>12:00 - 14:00 et 18:00 - 21h30</li>
                <li>12:00 - 14:00 et 18:00 - 21h30</li>
                <li>12:00 - 14:00 et 18:00 - 21h30</li>
                <li>12:00 - 14:00 et 18:00 - 21h30</li>
                <li>12:00 - 14:00 et 18:00 - 21h30</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="flex justify-center mt-14">
      <hr className="border-t-1 w-full" />
    </div>

    <div className="mt-5 h-[50px]">
      <div className="flex justify-center">
        <span className="text-gray-400 xs:text-xs sm:text-sm">© 2022 <a href="#snacking-grill" className="hover:text-gray-300"><span className="underline underline-offset-2 italic">Snacking Grill</span></a> – Restauration rapide, Grill & Burgers</span>
      </div>
    </div>
  </div>
  )
}

export default Home;