import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai';
import {FaPhoneAlt, FaSearchLocation, FaCaretDown, FaRegQuestionCircle, FaBookOpen} from 'react-icons/fa';


const Navbar = () => {

	const [toggleNav, setToggleNav] = useState(false);
	const [toggleProd, setToggleProd] = useState(false);
	const [toggleProdSM, setToggleProdSM] = useState(false);
	const menuSM = useRef();
	const prod = useRef();
	const prodSM = useRef();


	const handleToggleNav = () => {
		setToggleNav(!toggleNav);
	}

	const handleToggleProd = () => {
		setToggleProd(!toggleProd);
	}

	const handleToggleProdSM = () => {
		setToggleProdSM(!toggleProdSM);
	}

	const clickOutside = (e) => {
		if(prod.current.contains(e.target)) {
		  return;
		} 
		setToggleProd(false);
	}

	const clickOutsideSM = (e) => {
		if(prodSM.current.contains(e.target)) {
		  return;
		} 
		setToggleProdSM(false);
	}

	const clickOutsideSMMenu = (e) => {
		if(menuSM.current.contains(e.target)) {
		  return;
		} 
		setToggleNav(false);
	}

	useEffect(() => {
		document.addEventListener('mousedown', clickOutside);
		return () => {
			document.removeEventListener('mousedown', clickOutside);
		}
	}, [toggleProd])

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideSM);
		return () => {
			document.removeEventListener('mousedown', clickOutsideSM);
		}
	}, [setToggleProdSM])

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideSMMenu);
		return () => {
			document.removeEventListener('mousedown', clickOutsideSMMenu);
		}
	}, [setToggleNav])

	const offsetAnchor = () => {
		return window.scrollTo(window.scrollX, window.scrollY - 120);
	}

	const resetMobileMenu = () => {
		window.setTimeout(() => { offsetAnchor(); }, 0);
		setToggleNav(!toggleNav);
	}

	const resetMenu = () => {
		window.setTimeout(() => { offsetAnchor(); }, 0);
		if(toggleProd){
			setToggleProd(!toggleProd);
		}
	}

  return (
	<div className="w-full h-[90px] bg-amber-400 shadow-lg sticky top-0 z-50 select-none">
		<div className="max-w-[1240px] mx-auto px-4 flex justify-between items-center h-full">
			<div>
				<h1 className="font-architects font-extrabold cursor-pointer text-3xl"><span className="text-4xl">S</span>nacking <span className="text-4xl">G</span>rill</h1>
			</div>
			<div className="hidden md:flex">
				<ul className="flex font-medium">
					<li>
						<a href="tel:09 54 91 98 63" className="flex px-4 py-2 justify-center items-center cursor-pointer hover:bg-amber-200 hover:rounded-lg">
							<FaPhoneAlt /><span className="ml-2">09 54 91 98 63</span>
						</a>
					</li>
					<li>
						<a className="flex px-4 py-2 items-center cursor-pointer hover:bg-amber-200 hover:rounded-lg" href="https://www.google.com/maps/dir//Snacking+Grill-burger-restauration+rapide+a+Mane+3/@43.0829603,0.8812547,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12a8ddb2c9d66bb1:0xa9892f2ba4942a3d!2m2!1d0.9512945!2d43.0829817" target="_blank" rel="noreferrer">
							<FaSearchLocation /><span className="ml-2">Itinéraire</span>
						</a>
					</li>
					<li>
						<a href="menu" className="flex px-4 py-2 justify-center items-center cursor-pointer hover:bg-amber-200 hover:rounded-lg">
							<FaBookOpen /><span className="ml-2">Offres Week-end</span>
						</a>
					</li>
					<li className="md:relative">
						<button className={toggleProd ?"flex px-4 py-2 justify-center items-center font-medium bg-amber-200 rounded-lg" : "flex px-4 py-2 justify-center items-center font-medium hover:bg-amber-200 hover:rounded-lg" } onClick={handleToggleProd}><FaCaretDown /> Produits</button>
						<div ref={prod} className={toggleProd ? "bg-amber-300 absolute w-[200px] -left-[30px] shadow-md rounded-lg py-2" : "hidden"}>
							<ul className="space-y-2">
								<li onClick={() => resetMenu('#assiettes')}><a href="#assiettes" className="hover:bg-amber-200 flex px-2 py-1">Nos Assiettes</a></li>
								<li onClick={() => resetMenu('#burgers')}><a href="#burgers" className="hover:bg-amber-200 flex px-2 py-1">Nos Burgers</a></li>
								<li onClick={() => resetMenu('#tacos')}><a href="#tacos" className="hover:bg-amber-200 flex px-2 py-1">Nos Tacos</a></li>
								<li onClick={() => resetMenu('#kebabmaison')}><a href="#kebabmaison" className="hover:bg-amber-200 flex px-2 py-1">Nos Kebabs Maison</a></li>
								<li onClick={() => resetMenu('#paninis')}><a href="#paninis" className="hover:bg-amber-200 flex px-2 py-1">Nos Paninis</a></li>
								<li onClick={() => resetMenu('#americains')}><a href="#americains" className="hover:bg-amber-200 flex px-2 py-1">Nos Américains</a></li>
								<li onClick={() => resetMenu('#menus')}><a href="#menus" className="hover:bg-amber-200 flex px-2 py-1">Nos Menus</a></li>
								<li onClick={() => resetMenu('#nuggets')}><a href="#nuggets" className="hover:bg-amber-200 flex px-2 py-1">Nos Nuggets</a></li>
								<li onClick={() => resetMenu('#barquettes')}><a href="#barquettes" className="hover:bg-amber-200 flex px-2 py-1">Nos Barquettes</a></li>
								<li onClick={() => resetMenu('#sauces')}><a href="#sauces" className="hover:bg-amber-200 flex px-2 py-1">Nos Sauces</a></li>
								<li onClick={() => resetMenu('#boissons')}><a href="#boissons" className="hover:bg-amber-200 flex px-2 py-1">Nos Boissons</a></li>
							</ul>
						</div>
					</li>
					<li>
						<a onClick={() => resetMenu('#a-propos')} href="#a-propos" className="flex px-4 py-2 hover:bg-amber-200 hover:rounded-lg">A Propos</a>
					</li>
				</ul>
			</div>

			<div className="block md:hidden cursor-pointer" onClick={handleToggleNav}>
				{toggleNav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
			</div>

			<div ref={menuSM} className={toggleNav ? "w-full bg-amber-400 absolute top-[90px] left-0 shadow-lg flex py-2" : "absolute left-[-100%]"}>
				<ul className="w-full cursor-pointer font-medium space-y-2 px-4">
					<li className="text-xl hover:bg-amber-300 pl-2">
						<a href="tel:09 54 91 98 63" className="flex items-center cursor-pointer">
							<FaPhoneAlt /><span className="ml-2">09 54 91 98 63</span>
						</a>
					</li>
					<li className="text-xl hover:bg-amber-300 pl-2">
						<a className="flex items-center cursor-pointer" href="https://www.google.com/maps/dir//Snacking+Grill-burger-restauration+rapide+a+Mane+3/@43.0829603,0.8812547,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12a8ddb2c9d66bb1:0xa9892f2ba4942a3d!2m2!1d0.9512945!2d43.0829817" target="_blank" rel="noreferrer">
							<FaSearchLocation /><span className="ml-2">Itinéraire</span>
						</a>
					</li>
					<li className="text-xl hover:bg-amber-300 pl-2">
						<a className="flex items-center cursor-pointer" href="/menu" rel="noreferrer">
							<FaBookOpen /><span className="ml-2">Offres Week-end</span>
						</a>
					</li>
					<li className="text-xl pl-2">
						<button className={toggleProdSM ? "flex items-center w-full font-medium" : "flex items-center w-full text-left font-medium"} onClick={handleToggleProdSM}><FaCaretDown /> <span className="px-2">Produits</span></button>
						<div className={toggleProdSM ? "py-2" : "hidden"}>
							<ul className="space-y-2 px-8">
								<li onClick={() => resetMobileMenu('#assiettes')}><a href="#assiettes" className="hover:bg-amber-200 flex px-2 py-1">Nos Assiettes</a></li>
								<li onClick={() => resetMobileMenu('#burgers')}><a href="#burgers" className="hover:bg-amber-200 flex px-2 py-1">Nos Burgers</a></li>
								<li onClick={() => resetMobileMenu('#tacos')}><a href="#tacos" className="hover:bg-amber-200 flex px-2 py-1">Nos Tacos</a></li>
								<li onClick={() => resetMobileMenu('#kebabmaison')}><a href="#kebabmaison" className="hover:bg-amber-200 flex px-2 py-1">Nos Kebabs Maison</a></li>
								<li onClick={() => resetMobileMenu('#paninis')}><a href="#paninis" className="hover:bg-amber-200 flex px-2 py-1">Nos Paninis</a></li>
								<li onClick={() => resetMobileMenu('#americains')}><a href="#americains" className="hover:bg-amber-200 flex px-2 py-1">Nos Américains</a></li>
								<li onClick={() => resetMobileMenu('#menus')}><a href="#menus" className="hover:bg-amber-200 flex px-2 py-1">Nos Menus</a></li>
								<li onClick={() => resetMobileMenu('#nuggets')}><a href="#nuggets" className="hover:bg-amber-200 flex px-2 py-1">Nos Nuggets</a></li>
								<li onClick={() => resetMobileMenu('#barquettes')}><a href="#barquettes" className="hover:bg-amber-200 flex px-2 py-1">Nos Barquettes</a></li>
								<li onClick={() => resetMobileMenu('#sauces')}><a href="#sauces" className="hover:bg-amber-200 flex px-2 py-1">Nos Sauces</a></li>
								<li onClick={() => resetMobileMenu('#boissons')}><a href="#boissons" className="hover:bg-amber-200 flex px-2 py-1">Nos Boissons</a></li>
							</ul>
						</div>
					</li>
					<li className="text-xl hover:bg-amber-300 pl-2">
						<a onClick={()=> resetMobileMenu('#a-propos')} href="#a-propos" className="flex items-center"><FaRegQuestionCircle /><span className="px-2">A Propos</span></a>
					</li>
				</ul>
			</div>

		</div>
	</div>
  )
}

export default Navbar;