import React from 'react';


const CardSauce = ({title}) => {


  return (
	<div className="flex lg:grayscale lg:hover:grayscale-0 select-none">
		<div className="flex flex-col w-full pl-5">
			<h1 className="text-2xl font-semibold">{title}</h1>
		</div>
	</div>
  )
}

export default CardSauce;