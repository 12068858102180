import React from 'react';


const CardMenu = ({name, date, slot, reservationEnd, price, entree, plat, dessert}) => {


  return (
	<div className="flex lg:grayscale lg:hover:grayscale-0 select-none bg-gray-100 shadow-md md:hover:shadow-lg rounded">
		<div className="flex flex-col p-5 min-w-full">
			<h1 className="text-2xl font-semibold text-center">{name}</h1>
			<div className="flex justify-center mt-2">
				<hr className="border-t-2 border-amber-300 w-2/3"/>
			</div>
			<p className="pt-5"><span className="font-semibold">Dates:</span> {date}</p>
			<p className="pt-2"><span className="font-semibold">Nb. de places:</span> {slot}</p>
			<p className="pt-2"><span className="font-semibold">Fin de réservation:</span> {reservationEnd}</p>
			<br />
			<p className="pt-2 text-xl text-amber-500 font-bold text-center">{price} € 
				<br />
				<span className="text-md">par personne</span>
			</p>
			<br />
			{/*<p className="pt-2"><span className="font-semibold">Entrées:</span> {entree}</p>*/}
			<p className="pt-2"><span className="font-semibold">Plat:</span> {plat}</p>
			{/*{
				dessert !== "" ? <><p className="pt-2"><span className="font-semibold">Dessert:</span> {dessert}</p><br /></> : ""
			}*/}	
		</div>
	</div>
  )
}

export default CardMenu;
