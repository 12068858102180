import React from 'react';
import {FaPhoneAlt, FaSearchLocation} from 'react-icons/fa';
import itemsMenu from '../data/itemsMenu';
import CardMenu from '../components/CardMenu';

const Menu = () => {

  const CheckMenu= itemsMenu.some(itemMenu => {
    if (itemMenu.category === "Menu") {
      return true;
    }
    return false;
  });

  return (
  <div className="container mx-auto">

    <div className="flex justify-center select-none">
      <h1 className="font-bold text-xl border-t-2 border-gray-200">&emsp; Menus spéciaux samedi et dimanche &emsp;</h1>
    </div>

    <div className="bg-gray-100 xs:mt-5 md:mt-10 shadow-lg px-4 m-2">
      <div className="flex flex-col md:justify-center px-6 py-4">
          <h1 className="mt-4 font-semibold text-center">Informations relatives aux offres du Week-end</h1>
          <br />
          <p className="md:text-center">
            Notre équipe vous propose des menus <span className="font-semibold italic">Marocain</span> fait maison le samedi et dimanche midi, 
            sur <span className="font-semibold italic">réservation. Sur place ou à emporter</span>.
            <br />
          </p>
          <p className="md:text-center">
          Sur demande, nos menus peuvent être adaptés pour <span className="font-semibold italic">végétarien</span>. Des <span className="font-semibold italic">menus enfants</span> peuvent être proposer au moment de la réservation. 
            <br />
          </p>
          <p className="md:text-center text-red-700 sm:pt-3 xs:pt-3">
            <span className="underline underline-offset-[5px]">Un paiement d'avance vous sera demandé au moment de la réservation</span>.
          </p>
          <br />
      </div>
    </div>

    <div className="flex justify-center mt-14">
      <hr className="border-t-1 border-amber-300 w-1/3"/>
    </div>

    {
      CheckMenu &&
      <>
        <div className="flex sm:justify-center mt-10 px-5">
          <div className="grid grid-flow-row-dense xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8 gap-x-10">
              {
                itemsMenu.filter(itemMenu => itemMenu.category === "Menu").map(itemFiltered => {
                  return (
                    <CardMenu 
                      name={itemFiltered.name} 
                      date={itemFiltered.date} 
                      slot={itemFiltered.slot} 
                      reservationEnd={itemFiltered.reservationEnd} 
                      price={itemFiltered.price}
                      entree={itemFiltered.entree}
                      plat={itemFiltered.plat}
                      dessert={itemFiltered.dessert}
                      key={itemFiltered.name}
                    />
                  )
                })
              }
          </div>
        </div>
      </>
    }


    <div className="flex justify-center mt-14">
      <hr className="border-t-1 border-amber-300 w-1/3"/>
    </div>

    <div id="a-propos" className="bg-gray-100 mt-10 shadow-lg px-4 m-2">
      <div className="flex flex-col justify-center px-6 py-4">
      <h1 className="mt-4 font-semibold text-center">6 AVENUE DU COMMINGES 31260 MANE</h1>
          <br />
          <p className="text-justify">
          L’équipe du  <span className="font-semibold italic">Snacking Grill</span>  vous remercie pour votre visite et vous présente son établissement. 
          <br />
          L’accueil et le service sont assurés par <span className="font-semibold italic">Laurent</span> tandis que <span className="font-semibold italic">Nadia</span> s’occupe de vous cuisiner vos savoureux repas. 
          </p>
          <br />
          <p className="text-justify">Afin de conserver toutes les saveurs et vous proposer le meilleur, <span className="font-semibold italic">Nadia</span> cuisine tous les jours avec des légumes frais (salades, tomates, oignons… etc).
             Tout comme nous sélectionnons nos viandes (steak de bœuf, filet de poulet) avec grand soin chez notre boucher. </p>
          <br />
          <p className="text-justify">
          Nos nuggets et nos tenders sont faits maison à partir de filets de poulet; 
          tous nos pains sont faits sur place et nous vous proposons de déguster nos fameux desserts maison. 
          </p>
          <br />
          <p className="text-justify">Nous aurons le plaisir de vous accueillir et vous servir dans notre établissement sur place ou à emporter ! </p>
          <br />
          <p className="text-justify">
          Des places de parking <span className="font-semibold italic">gratuites</span>, attenantes au snack, sont mises à votre disposition. 
          Notre restaurant est accessible pour les personnes à mobilité réduite.
          </p>
      </div>
    </div>

    <div className="flex justify-center mt-14">
      <hr className="border-t-1 border-amber-300 w-1/3" />
    </div>

    <div id="horraires" className="bg-gray-100 mt-10 shadow-lg m-2">
      <div className="flex xs:flex-col sm:flex-col md:flex-row justify-center px-6 py-4">
        <div className="basis-1/3 px-4">
          <h1 className="text-center uppercase font-semibold">Coordonnées</h1>
          <div className="flex flex-col justify-center text-center mt-5">
            <a className="bg-amber-400 hover:bg-amber-300 h-[50px] flex items-center justify-center rounded-lg" href="tel:09 54 91 98 63" target="_blank" rel="noreferrer"><FaPhoneAlt /><span className="ml-1 font-semibold">Appeler</span></a>
            <span className="mt-2">09 54 91 98 63</span>
          </div>
        </div>
        <div className="basis-1/3 px-4 xs:mt-10 sm:mt-10 md:mt-0">
          <h1 className="text-center uppercase font-semibold">Adresse</h1>
          <div className="flex flex-col text-center mt-5">
            <a className="bg-amber-400 hover:bg-amber-300 h-[50px] flex items-center justify-center rounded-lg" href="https://www.google.com/maps/dir//Snacking+Grill-burger-restauration+rapide+a+Mane+3/@43.0829603,0.8812547,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12a8ddb2c9d66bb1:0xa9892f2ba4942a3d!2m2!1d0.9512945!2d43.0829817" target="_blank" rel="noreferrer"><FaSearchLocation /><span className="ml-1 font-semibold">Itinéraire</span></a>
          </div>
        </div>
        <div className="basis-1/3 px-4 xs:mt-10 sm:mt-10 md:mt-0">
          <h1 className="text-center uppercase font-semibold">Horaires d'ouverture</h1>
          <div className="flex flex-row justify-center mt-5">
            <div className="text-right">
              <ul>
                <li>Lun:</li>
                <li>Mar:</li>
                <li>Mer:</li>
                <li>Jeu:</li>
                <li>Ven:</li>
                <li>Sam: </li>
                <li>Dim:</li>
              </ul>
            </div>
            <div className="ml-2">
              <ul>
                <li>Fermé</li>
                <li>Fermé</li>
                <li>11:30 - 13:30 et 18:00 - 21h30</li>
                <li>11:30 - 13:30 et 18:00 - 21h30</li>
                <li>11:30 - 13:30 et 18:00 - 21h30</li>
                <li>11:30 - 13:30 et 18:00 - 21h30</li>
                <li>11:30 - 13:30 et 18:00 - 21h30</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="flex justify-center mt-14">
      <hr className="border-t-1 w-full" />
    </div>

    <div className="mt-5 h-[50px]">
      <div className="flex justify-center">
        <span className="text-gray-400 xs:text-xs sm:text-sm">© 2022 <a href="#snacking-grill" className="hover:text-gray-300"><span className="underline underline-offset-2 italic">Snacking Grill</span></a> – Restauration rapide, Grill & Burgers</span>
      </div>
    </div>
  </div>
  )
}

export default Menu;