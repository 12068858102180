let items = [
    {
		"name": "Steak haché",
		"category": "Assiettes",
		"url": "kebabplate.jpg",
		"desc": "Steak haché grillé servi avec nos frites maison et crudités",
		"price": "9,50"
	},
	{
		"name": "Kebab",
		"category": "Assiettes",
		"url": "kebabplate.jpg",
		"desc": "Kebab servi avec nos frites maison, crudités et sauce fromagère maison",
		"price": "9,50"
	},
	{
		"name": "Tenders",
		"category": "Assiettes",
		"url": "kebabplate.jpg",
		"desc": "Tenders maison servi avec nos frites maison et crudités",
		"price": "9,50"
	},
	{
		"name": "Classique burger",
		"category": "Burgers",
		"url": "cheeseburger.jpg",
		"desc": "Steak, fromage, oignons, salade (sauce au choix)",
		"price": "7,50"
	},
	{
		"name": "Oeuf burger",
		"category": "Burgers",
		"url": "cheeseburger.jpg",
		"desc": "Steak, oeuf, fromage, oignons, salade (sauce au choix)",
		"price": "8,00"
	},
	{
		"name": "Double spécial burger",
		"category": "Burgers",
		"url": "cheeseburger.jpg",
		"desc": "2 Steaks, 2 cheedar, oignons, salade, tomate (sauce au choix)",
		"price": "11,50"
	},
	{
		"name": "Tenders burger",
		"category": "Burgers",
		"url": "cheeseburger.jpg",
		"desc": "Tenders maison, salade, oignons, (sauce au choix)",
		"price": "9,00"
	},
	{
		"name": "Raclette burger",
		"category": "Burgers",
		"url": "cheeseburger.jpg",
		"desc": "Steak, galette de pomme de terre, fromage raclette, oignons, salade (sauce au choix)",
		"price": "9,50"
	},
	{
		"name": "Kebab",
		"category": "Kebab pain maison",
		"url": "kebab.jpg",
		"desc": "Kebab, tomate, oignon, salade, sauce fromagère maison",
		"price": "8,50"
	},
	{
		"name": "Kebab frite",
		"category": "Kebab pain maison",
		"url": "kebab.jpg",
		"desc": "Kebab, tomate, oignon, salade, frite maison, sauce fromagère maison",
		"price": "9,50"
	},
	{
		"name": "Panini jambon fromage",
		"category": "Paninis",
		"url": "panini.jpg",
		"desc": "Jambon, fromage",
		"price": "6,50"
	},
	{
		"name": "Panini trois fromages",
		"category": "Paninis",
		"url": "panini.jpg",
		"desc": "Brie, chèvre, emmental",
		"price": "7,00"
	},
	{
		"name": "Panini chèvre miel",
		"category": "Paninis",
		"url": "panini.jpg",
		"desc": "Chèvre, miel",
		"price": "6,50"
	},
	{
		"name": "Panini steak",
		"category": "Paninis",
		"url": "panini.jpg",
		"desc": "Steak, brie",
		"price": "7,00"
	},
	{
		"name": "Panini poulet",
		"category": "Paninis",
		"url": "panini.jpg",
		"desc": "Poulet, emmental, sauce curry",
		"price": "7,00"
	},
	{
		"name": "Panini nutella",
		"category": "Paninis",
		"url": "panini.jpg",
		"desc": "Nutella",
		"price": "3,50"
	},
	{
		"name": "Américain steak",
		"category": "Américains",
		"url": "americain.jpg",
		"desc": "Steak, frites maison, salade, tomate, oignons, cheedar",
		"price": "10,00"
	},
	{
		"name": "Américain tenders",
		"category": "Américains",
		"url": "americain.jpg",
		"desc": "Tenders maison, frites maison, salade, tomate, oignons",
		"price": "10,00"
	},
	{
		"name": "Américain kebab",
		"category": "Américains",
		"url": "americain.jpg",
		"desc": "Kebab, frites maison, salade, tomate, oignons",
		"price": "10,00"
	},
	{
		"name": "Tacos steak",
		"category": "Tacos",
		"url": "tacos.jpg",
		"desc": "Salade, frites maison, steak, sauce fromagère maison",
		"price": "9,50"
	},
	{
		"name": "Tacos Tenders",
		"category": "Tacos",
		"url": "tacos.jpg",
		"desc": "Salade, frites maison, Tenders maison, sauce fromagère maison",
		"price": "9,50"
	},
	{
		"name": "Tacos kebab",
		"category": "Tacos",
		"url": "tacos.jpg",
		"desc": "Salade, frites maison, kebab, sauce fromagère maison",
		"price": "9,50"
	},
	{
		"name": "Tacos 3 viandes",
		"category": "Tacos",
		"url": "tacos.jpg",
		"desc": "Salade, frites maison, kebab, Tenders maison, steak, sauce fromagère maison",
		"price": "14,00"
	},
	{
		"name": "Nuggets x4",
		"category": "Nuggets",
		"url": "nuggets.jpg",
		"desc": "Nuggets plein filet maison",
		"price": "4,50"
	},
	{
		"name": "Nuggets x8",
		"category": "Nuggets",
		"url": "nuggets.jpg",
		"desc": "Nuggets plein filet maison",
		"price": "8,50"
	},
	{
		"name": "Petite frites",
		"category": "Frites",
		"url": "frite.jpg",
		"desc": "Barquette d'environs 150 g de frites maison",
		"price": "2,50"
	},
	{
		"name": "Moyenne frites",
		"category": "Frites",
		"url": "frite.jpg",
		"desc": "Barquette d'environs 250 g de frites maison",
		"price": "3,50"
	},
	{
		"name": "Grande frites",
		"category": "Frites",
		"url": "frite.jpg",
		"desc": "Barquette d'environs 500 g de frites maison",
		"price": "4,50"
	},
	{
		"name": "Menu enfant",
		"category": "Menus",
		"url": "menu.png",
		"desc": "Au choix: Burger classique ou 4 nuggets ou panini jambon fromage + frites maison + boisson",
		"price": "9,00"
	},
	{
		"name": "Menu adulte",
		"category": "Menus",
		"url": "menu.png",
		"desc": "Au choix: kebab ou panini ou burger (hors double burger) ou tacos (hors 3 viandes) + frites maison + boisson + dessert",
		"price": "14,00"
	},
	{
		"name": "Sauce blanche pita",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce mayonnaise",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce ketchup",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce barbecue",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce algérienne",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce andalouse",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce burger",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce samouraï",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce moutarde",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Sauce harissa",
		"category": "Sauces",
		"url": "",
		"desc": "",
		"price": ""
	},
	{
		"name": "Coca",
		"category": "Boissons",
		"url": "coca.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Coca Cherry",
		"category": "Boissons",
		"url": "coca-cherry.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Coca zéro",
		"category": "Boissons",
		"url": "coca-zero.png",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Oasis tropical",
		"category": "Boissons",
		"url": "oasis-tropical.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Perrier",
		"category": "Boissons",
		"url": "perrier.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Orangina",
		"category": "Boissons",
		"url": "orangina.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Fanta citron",
		"category": "Boissons",
		"url": "fanta-citron.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Ice tea",
		"category": "Boissons",
		"url": "ice-tea.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Capri-sun",
		"category": "Boissons",
		"url": "capri-sun.jpg",
		"desc": "33cl",
		"price": "2,00"
	},
	{
		"name": "Eau",
		"category": "Boissons",
		"url": "eau.jpg",
		"desc": "33cl",
		"price": "1,50"
	},



  ]

  export default items;