import React from 'react';
import { Route, Routes, useLocation} from 'react-router-dom';
import Navbar from './components/Navbar';
import Navbar2 from './components/Navbar2';
import Header from './components/Header';
import Home from './pages/Home';
import Menu from './pages/Menu';

function App() {
  return (
    <div className="App">
      {
        useLocation().pathname === "/" ? <Navbar /> : <Navbar2 />
      }
      <Header />
      <div className="mt-10">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="menu" element={<Menu />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;