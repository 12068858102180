let itemsMenu = [
    {
		"category": "Menu",
		"name": "Couscous Royal",
		"date": "Samedi 08 ou Dimanche 09 juin",
		"slot": "Sur place ou à emporter",
		"reservationEnd": "Jeudi 06 juin",
		"price": "20,00",
		"entree": "Assortiment de salades marocaines",
		"plat": "Couscous Royal (Merguez, poulet et agneau)",
		"dessert": "Pâtisseries marocaines",
	},
	{
		"category": "Menu",
		"name": "Tajine Agneau et Légumes",
		"date": "Samedi 15 ou Dimanche 16 juin",
		"slot": "Sur place ou à emporter",
		"reservationEnd": "Jeudi 13 juin",
		"price": "15,00",
		"entree": "Assortiment de salades marocaines",
		"plat": "Tajine Agneau et Légumes",
		"dessert": "Pâtisseries marocaines",
	},
	{
		"category": "Menu",
		"name": "Couscous Royal",
		"date": "Samedi 22 ou Dimanche 23 juin",
		"slot": "Sur place ou à emporter",
		"reservationEnd": "Jeudi 20 juin",
		"price": "20,00",
		"entree": "Assortiment de salades marocaines",
		"plat": "Couscous Royal (Merguez, poulet et agneau)",
		"dessert": "Pâtisseries marocaines",
	},
	{
		"category": "Menu",
		"name": "Tajine de poulet",
		"date": "Samedi 29 ou Dimanche 30 juin",
		"slot": "Sur place ou à emporter",
		"reservationEnd": "Jeudi 27 juin",
		"price": "15,00",
		"entree": "Assortiment de salades marocaines",
		"plat": "Tajine de poulet au citron confit",
		"dessert": "Pâtisseries marocaines",
	},
  ]

  export default itemsMenu;