import React from 'react';


const CardDrink = ({title, desc, cat, img, price}) => {


  return (
	<div className="flex lg:grayscale lg:hover:grayscale-0 select-none">
		<div className="w-1/3 place-content-center h-[0%] pb-[33%] rounded-full overflow-hidden relative">
			<img src={process.env.PUBLIC_URL + '/images/' + img} alt={title} className="h-[100%] absolute w-auto left-[50%] max-w-initial -translate-x-[50%]" />
		</div>
		<div className="flex flex-col w-2/3 pl-5">
			<h1 className="text-2xl font-semibold border-b-2 border-amber-300">{title}</h1>
			<span className="pt-2">{desc}</span>
			<span className="pt-2 text-sm text-amber-500 font-bold">{price} €</span>
		</div>
	</div>
  )
}

export default CardDrink;